export default {
  "app_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Replay Dashboard"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hello i18n !!"])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
  "errors": {
    "login": {
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oops..."])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bad credentials Please try again."])}
    }
  },
  "workstation": {
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Workstation"])},
    "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Workstation"])},
    "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of Workstations"])}
  },
  "buttons": {
    "signin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign In"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
    "prev": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previous"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])}
  },
  "dashboard": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
    "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users"])},
    "playDuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Play Duration"])},
    "sensors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sensors"])},
    "games": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Games"])},
    "creation_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creation Date"])},
    "last_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Active"])},
    "number_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number Sessions"])},
    "played": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Played"])},
    "sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sessions"])},
    "single": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Single:"])},
    "multi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multiplayer:"])},
    "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
    "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
    "action_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Actions"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])}
  },
  "org": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organizations"])},
    "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Organization"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Organization"])}
  },
  "form": {
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Email"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Name"])},
    "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Name"])},
    "sensor_single": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Single Sensor"])},
    "sensor_multi1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sensor Multiplayer 1"])},
    "sensor_multi2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sensor Multiplayer 2"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
    "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send Reset Email"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "singlePlayerSensor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Single Player Sensor"])},
    "multiplayer1Sensor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multiplayer 1 Sensor"])},
    "multiplayer2Sensor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multiplayer 2 Sensor"])},
    "noChange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Change in Data"])}
  },
  "Missing fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Missing fields"])},
  "Email already exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email already exists"])},
  "no_record": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorry, No record found."])}
}