/**
 * Function to set the theme.
 * @since v1.0.0
 * @returns null
 */
const themeSetup = () => {
  document.querySelector('body')?.classList.add('bg-secondary');
}

const clearCache = async () => {
  // clear local storage all data.
  await localStorage.clear();

  // clear all cache data.
  await caches.keys().then((keyList: any) =>
    Promise.all(
      keyList.map((key: any) => {
          return caches.delete(key);
      })
    )
  )
}


export {
  themeSetup,
  clearCache
}
