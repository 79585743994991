export default {
  "app_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wiederholung Dashboard"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort"])},
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hallo i18n !!"])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suche"])},
  "errors": {
    "login": {
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ups..."])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Falsche Zugangsdaten. Bitte versuchen Sie es erneut."])}
    }
  },
  "workstation": {
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbeitsplatz bearbeiten"])},
    "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neuer Arbeitsplatz"])},
    "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl der Arbeitsplätze"])}
  },
  "buttons": {
    "signin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmelden"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiter"])},
    "prev": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorherige"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hinzufügen"])}
  },
  "dashboard": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Armaturenbrett"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakt"])},
    "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer"])},
    "playDuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spielzeit"])},
    "sensors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sensoren"])},
    "games": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spiele"])},
    "creation_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellungsdatum"])},
    "last_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zuletzt aktiv"])},
    "number_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl der Sitzungen"])},
    "played": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gespielt"])},
    "sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sitzungen"])},
    "single": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einzel"])},
    "multi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mehrspieler"])},
    "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sicht"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bearbeiten"])},
    "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktionen"])},
    "action_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sicht Aktionen"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Löschen"])}
  },
  "org": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisationen"])},
    "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Organisation"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisation bearbeiten"])}
  },
  "form": {
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakt E-Mail"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort"])},
    "first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorname"])},
    "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachname"])},
    "sensor_single": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einzelner Sensor"])},
    "sensor_multi1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mehrspieler-Sensor 1"])},
    "sensor_multi2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mehrspieler-Sensor 2"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern"])},
    "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset-E-Mail senden"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfolg"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "singlePlayerSensor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sensor für Einzelspieler"])},
    "multiplayer1Sensor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mehrspieler-Sensor 1"])},
    "multiplayer2Sensor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mehrspieler-Sensor 2"])},
    "noChange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Datenänderung"])}
  },
  "Missing fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehlende Felder"])},
  "Email already exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail existiert bereits"])},
  "no_record": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leider keine Aufzeichnung gefunden."])}
}