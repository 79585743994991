export default {
  "app_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tableau de bord de relecture"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe"])},
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salut i18n !!"])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher"])},
  "errors": {
    "login": {
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oups..."])},
      "body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mauvaises informations d'identification. Veuillez réessayer."])}
    }
  },
  "workstation": {
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier la station de travail"])},
    "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvelle station de travail"])},
    "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de postes de travail"])}
  },
  "buttons": {
    "signin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se connecter"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suivant"])},
    "prev": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Précédent"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter"])}
  },
  "dashboard": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tableau de bord"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
    "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateurs"])},
    "playDuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durée de jeu"])},
    "sensors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capteurs"])},
    "games": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeux"])},
    "creation_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de création"])},
    "last_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dernier actif"])},
    "number_sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de sessions"])},
    "played": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Joué"])},
    "sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sessions"])},
    "single": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Individuel"])},
    "multi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multijoueur"])},
    "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier"])},
    "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
    "action_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir Actions"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer"])}
  },
  "org": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisations"])},
    "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvelle organisation"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier l'organisation"])}
  },
  "form": {
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail de contact"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe"])},
    "first_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénom"])},
    "last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
    "sensor_single": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capteur individuel"])},
    "sensor_multi1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capteur multijoueur 1"])},
    "sensor_multi2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capteur multijoueur 2"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer"])},
    "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer l'e-mail de réinitialisation"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succès"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
    "singlePlayerSensor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capteur joueur unique"])},
    "multiplayer1Sensor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capteur multijoueur 1"])},
    "multiplayer2Sensor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capteur multijoueur 2"])},
    "noChange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun changement de données"])}
  },
  "Missing fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Champs manquants"])},
  "Email already exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'e-mail existe déjà"])},
  "no_record": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Désolé, aucun enregistrement trouvé."])}
}